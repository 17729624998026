import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Videos from "./pages/Videos";
import Direct from "./pages/Direct";
import Root from "./Root";
import Copyrights from "./pages/Copyrights";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import VideoDetails from "./pages/VideoDetails";
import Policy from "./pages/Policy";
import Contact from "./pages/Contact";
import AppContextProvider from "./context/AppContext";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Favorites from "./pages/Favorites";
import AboutUs from "./pages/AboutUs";



function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root/>,
            errorElement: <NotFound/>,
            children: [
                {
                    path: "",
                    element: <Home/>,
                },
                {
                    path: "about",
                    element: <AboutUs/>,
                },
                {
                    path: "videos-player",
                    element: <VideoDetails/>,
                },
                {
                    path: "videos",
                    element: <Videos/>,
                },
                {
                    path: "favorites",
                    element: <Favorites/>,
                },
                {
                    path: "live",
                    element: <Direct/>,
                },
                {
                    path: "information",
                    element: <Copyrights/>,
                },
                {
                    path: "contact",
                    element: <Contact/>,
                },
                {
                    path: "terms",
                    element: <Copyrights/>,
                },
                {
                    path: "policy",
                    element: <Policy/>,
                },
                {
                    path: "connexion",
                    element: <Login/>,
                },
                {
                    path: "mot-de-passe-oublie",
                    element: <ForgetPassword/>,
                },
            ]
        },
    ], {});


    return (
        <AppContextProvider>
            <>
                <RouterProvider router={router}/>
            </>
        </AppContextProvider>
    );
}

export default App;
