import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {AppContext} from "../context/AppContext";

export default function useToast() {
    const navigate = useNavigate();
    const {windowWidth} = useContext(AppContext)
    const toastContent = "Connexion requise pour accéder à ce contenu : Clique ici 👈"
    const triggerConnectionRequieredToast = () => toast(toastContent, {
        theme: 'dark',
        type: 'info',
        position: windowWidth < 500 ? 'bottom-center' : 'top-right',
        style: {textDecoration:'underline'},
        onClick: () => {
            navigate('/connexion')
        },

        hideProgressBar: false,
    })

    return {triggerConnectionRequieredToast};
}

