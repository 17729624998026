import React, {useContext, useEffect, useState} from 'react';
import Shell from "../components/Shell";
import {Themes, Video} from "../models/Video";
import {useLocation, useNavigate} from "react-router-dom";
import {MutatingDots} from "react-loader-spinner";
import useVideo from "../hook/useVideo";
import {toast} from "react-toastify";
import useUser from "../hook/useUser";
import {AppContext} from "../context/AppContext";
import VideosSlider from "../components/VideosSlider";

export default function VideoDetails() {

    const location = useLocation();
    const {session, allVideos} = useContext(AppContext);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const {getVideoById} = useVideo()
    const videoId = queryParams.get('id')
    const [isVideoLoading, setIsVideoLoading] = useState(true)
    //TODO: add loader specific to the YT iframe
    // const [isIframeLoading, setIsIframeLoading] = useState(false)
    const [video, setVideos] = useState<Video | undefined>(undefined)
    const moreVideosOfVideoTheme: Video[] = [];
    const {postVideoToFavorite} = useUser()
    const {filterVideosByTheme} = useVideo()


    useEffect(() => {
        getVideoById(videoId!)
            .then((res) => {
                setVideos(res!)
                setIsVideoLoading(false)
            })
    }, []);

    if (video?.theme && allVideos.length > 0) {
        moreVideosOfVideoTheme.push(...filterVideosByTheme(video.theme, allVideos.filter(elt => elt.id !== video.id))!)
    }

    const addToFavorite = () => {
        const toastId = toast.loading(`Adding ${video!.title} to favorite...`, {theme: 'dark'})
        postVideoToFavorite(session!.user.email!, video!.id)
            .then(() => toast.update(toastId, {
                render: `${video!.title} added to favorite!`, type: "success", isLoading: false,
                onClick: () => navigate('/favorites'),
                autoClose: 3000
            }))
            .catch(() => toast.update(toastId, {
                render: `Error adding ${video!.title} to favorite, retry late.`,
                type: "error"
            }))

    }

    const renderVideoDetail = () => {
        return (
            video ? (<>
                    <div className={'video-container'}>
                        <div className={"row align-items-center justify-content-center"}
                             style={{position: 'relative', height: '100%'}}>
                            {/*{isIframeLoading ?*/}

                            {/*        <MutatingDots*/}
                            {/*            visible={true}*/}
                            {/*            height="100"*/}
                            {/*            width="100"*/}
                            {/*            color="#e74e21"*/}
                            {/*            secondaryColor="#e74e21"*/}
                            {/*            radius="12.5"*/}
                            {/*            ariaLabel="mutating-dots-loading"*/}
                            {/*            wrapperStyle={{}}*/}
                            {/*            wrapperClass=""*/}
                            {/*        /> :*/}
                            <iframe style={{width: '100%', position: 'absolute', left: 0, top: 0, height: '100%'}}
                                    src={video.url}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                            {/*}*/}
                        </div>
                    </div>
                    <section className="play-details">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="play-thumb mb-4">
                                                <img alt="" className="img-fluid"
                                                     src={`../images/videos/${video.thumbPath}`}/>
                                                <div className="top-badge">
                                                    <div className="video-badge">
                                                        {/*<img alt="" className="img-fluid" src={`${video.thumbPath}`}/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Col End */}
                                    </div>
                                    {/* Row End */}
                                </div>
                                {/* Col End */}
                                <div className="col-md-7">
                                    <div className="play-details-content">
                                        <div className="title-block d-flex align-items-center justify-content-between">
                                            <h2 className="play-title" style={{marginTop: 0}}>{video.title}</h2>
                                        </div>
                                        {/* Title Block */}
                                        <div className="details-info mb-4">
                                            {video.age &&
                                                <span>
                                        <i className="icofont-user mr-2" aria-hidden="true"/> {video.age}+
                                    </span>}
                                            {video.duration &&
                                                <span>
                                        <i className="icofont-clock-time mr-2" aria-hidden="true"/> {video.duration}
                                     </span>}
                                            {video.theme &&
                                                <span>
                                        <i className="icofont-movie mr-2" aria-hidden="true"/> {video.theme}
                                    </span>}
                                        </div>
                                        {/* Details Info */}
                                        <div className="details-desc">
                                            <p>{video.description}</p>
                                        </div>
                                        <div className="details-buttons">
                                            <div className="row d-flex align-items-center">
                                                {/* Col End */}
                                                <div className="col-6 col-xl mb-xl-0">
                                                    <a
                                                        className="btn d-block hvr-sweep-to-right"
                                                        tabIndex={0}
                                                        onClick={() => {
                                                            addToFavorite()
                                                        }}
                                                    >
                                                        <i className="icofont-plus mr-2" aria-hidden="true"/>
                                                        Ajouter à ma liste
                                                    </a>
                                                </div>
                                                {/* Col End */}

                                                {/* Col End */}
                                                <div className="col-6 col-xl mb-xl-0">
                                                    <a
                                                        id="share"
                                                        className="btn hvr-sweep-to-right d-block"
                                                        tabIndex={0}
                                                        data-toggle="modal"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(window.location.href).catch();
                                                            toast(`${video?.title} copié dans le press-papier ! 📄 `, {
                                                                type: "info",
                                                                theme: "dark",
                                                                autoClose: 3000,
                                                                closeOnClick: true,
                                                            })
                                                        }}
                                                    >
                                                        <i className="icofont-share mr-2" aria-hidden="true"/>
                                                        Partager
                                                    </a>
                                                    {/* Modal Share */}
                                                    <div
                                                        className="modal fade"
                                                        id="share-modal"
                                                        tabIndex={0}
                                                        role="dialog"
                                                        aria-labelledby="share-modal"
                                                        aria-hidden="true"
                                                    >
                                                        <div
                                                            className="modal-dialog modal-lg"
                                                            role="document"
                                                            id="sharemodal"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Share</h5>
                                                                    <button
                                                                        type="button"
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                    >
                            <span aria-hidden="true">
                              <i className="fas fa-times"/>
                            </span>
                                                                    </button>
                                                                </div>
                                                                {/* modal header End */}
                                                                <div className="modal-body">
                                                                    <div className="icon-container d-flex">
                                                                        <div className="icon-block">
                                                                            <i className="social-icon fab fa-twitter fa-2x"/>
                                                                            <p>Twitter</p>
                                                                        </div>
                                                                        <div className="icon-block">
                                                                            <i className="social-icon fab fa-facebook fa-2x"/>
                                                                            <p>Facebook</p>
                                                                        </div>
                                                                        <div className="icon-block">
                                                                            <i className="social-icon fab fa-instagram fa-2x"/>
                                                                            <p>Instagram</p>
                                                                        </div>
                                                                        <div className="icon-block">
                                                                            <i className="social-icon fab fa-telegram fa-2x"/>
                                                                            <p>Telegram</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Modal Body End */}
                                                            </div>
                                                            {/* Modal Content End */}
                                                        </div>
                                                        {/* Modal Dialog End */}
                                                    </div>
                                                    {/* Modal Share End */}
                                                </div>
                                                {/* Col End */}
                                            </div>
                                            {/* Row End */}
                                        </div>
                                        {/* Details Buttons End */}
                                    </div>
                                    {/* Details Content End */}
                                </div>
                                {/* Col End */}
                            </div>
                            {/* row End */}
                        </div>
                    </section>
                </>)
                :
                <div className={"row align-items-center justify-content-center"} style={{height: "90vh"}}>Aucune vidéo
                    n'a été
                    sélectionnée &#128533; </div>
        )
    }


    return (
        <Shell>
            <>
                {isVideoLoading ? <div className={"video-container row align-items-center justify-content-center"}>
                    <MutatingDots
                    visible={true}
                    height="100"
                    width="100"
                    color="#e74e21"
                    secondaryColor="#e74e21"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                /></div> : renderVideoDetail()}

                {moreVideosOfVideoTheme && moreVideosOfVideoTheme.length !== 0 &&
                    <VideosSlider key={video?.theme} title={video!.theme} videos={moreVideosOfVideoTheme}/>}

                {/* Container End */}
            </>
        </Shell>
    )
        ;

}
