import React, {useContext} from 'react';
import logo from "../images/logo/header-logo.png"
import {Link} from "react-router-dom";
import {Themes} from "../models/Video";
import {AppContext} from "../context/AppContext";
import useToast from "../hook/useToast";


export default function Footer() {

    const {windowWidth, session} = useContext(AppContext);
    const {triggerConnectionRequieredToast} = useToast()


    return (
        <>
            <div className="main-footer">
                <div className="container-fluid">
                    <div className="row justify-content-lg-between justify-content-center">
                        <div className="col-md-3">
                            <div className="footer-widget">
                                <div className="widget-content">
                                    <div className="footer-logo">
                                        <img
                                            alt=""
                                            className="img-fluid"
                                            width={100}
                                            src={logo}
                                        />
                                    </div>
                                    <div className="footer-about-text">
                                        <p className="text-muted">
                                            Explore l'Asian Culture avec GONG et GONG MAX depuis 2009 : Dramas, Anime,
                                            KPOP, et reportages sur les jeux vidéo.
                                            Disponible sur ta TV et ton mobile.
                                        </p>
                                    </div>
                                    <div className="footer-social-icons">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <a href="https://twitter.com/GONGTweet" title="twitter">
                                                    <i className="fab fa-2x fa-twitter"/>
                                                </a>
                                            </li>
                                            {/* Social Icons Item 1 End */}
                                            <li className="list-inline-item">
                                                <a href="https://www.facebook.com/GONG" title="facebook">
                                                    <i className="fab fa-2x fa-facebook-f"/>
                                                </a>
                                            </li>
                                            {/* Social Icons Item 2 End */}
                                            <li className="list-inline-item">
                                                <a href="https://instagram.com/gongnetworks" title="instagram">
                                                    <i className="fab fa-2x fa-instagram"/>
                                                </a>
                                            </li>
                                            {/* Social Icons Item 3 End */}
                                            <li className="list-inline-item">
                                                <a href="https://www.youtube.com/user/GONGFrance" title="youtube">
                                                    <i className="fab fa-2x fa-youtube"/>
                                                </a>
                                            </li>
                                            {/* Social Icons Item 4 End */}
                                        </ul>
                                    </div>
                                    {/* Social Icons End */}
                                </div>
                                {/* Footer Widget Content End */}
                            </div>
                            {/* Footer Widget End */}
                        </div>
                        <div className="col-md-3">

                            <div className="widget-header">
                                <h2 className="widget-title">Videos themes</h2>
                            </div>
                            <div className="widget-content footer-menu">
                                <ul className="f-link list-unstyled mb-0">
                                    {Object.values(Themes).map((theme) => {
                                        return (<li key={theme} onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>

                                            <Link to={`/videos?theme=${theme}`}>{theme}</Link>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">

                            <div className="widget-header" style={{ marginTop: windowWidth <= 767 ? '2rem' : 0}}>
                                <h2 className="widget-title">Menu</h2>
                            </div>
                            <div className="widget-content footer-menu">
                                <ul className="f-link list-unstyled mb-0">
                                    <li>
                                        <Link to="/">ACCUEIL</Link>
                                    </li>
                                    <li>
                                        {session ?
                                            <Link
                                                to={'/live'}>DIRECT</Link>
                                            :
                                            <Link
                                                to={''} onClick={() => {
                                                triggerConnectionRequieredToast()
                                            }}>DIRECT</Link>
                                        }
                                    </li>
                                    <li>
                                        <Link to="/videos">VIDEOS</Link>
                                    </li>
                                    <li>
                                        <Link
                                            target={'_blank'} to="https://www.dramapassion.com/">DRAMAPASSION</Link>
                                    </li>
                                    <li>
                                        <Link to="/information">A PROPOS</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Widget Content End */}
                    </div>
                    {/* Row End */}
                </div>
                {/* Container End */}
            </div>
            <div className="copyright">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <p>TM&©2024 GONG MEDIA / OTT MEDIA. All rights reserved.</p>
                        </div>
                        {/* Col End */}
                        <div className="col-md-6">
                            <div className="copyright-menu text-right">
                                <ul>
                                    <li>
                                        <Link to={'/contact'}>Contacte-nous</Link>
                                    </li>
                                    <li>
                                        <Link to={'/terms'}>Copyrights</Link>
                                    </li>
                                    <li>
                                        <Link to={'/policy'}>Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* Copyrights Menu End */}
                        </div>
                        {/* Col End */}
                    </div>
                    {/* Row End */}
                </div>
                {/* Container End */}
            </div>

        </>

    );
};
