import React from 'react';
import HomePageCarouselItem, {CarouselItemProps} from "./HomePageCarouselItem";
import carouselImage1 from "../images/carousel/dramapassion.jpeg";
import bgDramaVoGong from "../images/carousel/drama-vo-gong.jpeg";
import bgGameology from "../images/carousel/carousel-gameology.jpeg";
import bgYuGiOh from "../images/carousel/yugi.png";
import bgKpop from "../images/carousel/kpop-star24.jpeg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";


export default function HomePageCarousel() {

    const carouselItems: CarouselItemProps[] = [
        {
            id: 0,
            backgroundImage: bgDramaVoGong,
            description: 'Les seules chaines TV a diffuser des dramas coréens en VO. Demande la chaine à ton opérateur favoris !',
            duration: '',
            minimumAge: '+12',
            title: 'DRAMAS EN VO sur GONG et GONG MAX',
            buttons: [
                {
                    label: "LIVE GONG",
                    to: '/live',
                    icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
                }
            ]
        },
        {
            id: 1,
            backgroundImage: bgYuGiOh,
            description: 'Retrouve le meilleur de Yo-Gi-Oh! tous les matins à 7h00 sur GONG !',
            duration: '',
            minimumAge: '+4',
            title: 'Yu-Gi-Oh!',
            buttons: [
                {
                    label: "LIVE GONG",
                    to: '/live',
                    icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
                }
            ]
        }, {
            id: 3,
            backgroundImage: bgGameology,
            description: 'Les tests de jeux-vidéo dans l’émission culte et décalée de Johann et Gérard.',
            duration: '11m',
            minimumAge: '+8',
            title: 'GAMEOLOGY',
            buttons: [
                {
                    label: "JEUX VIDEO",
                    to: {pathname: '/videos', search: '?theme=Jeux vidéos'},
                    icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
                }
            ]
        },
        {
            id: 4,
            backgroundImage: carouselImage1,
            description: 'Premier site francophone de streaming de dramas coréens en VO, sous-titrés par des professionnels.',
            duration: '',
            minimumAge: '+12',
            title: 'DRAMAPASSION',
            buttons: [
                {
                    label: "DRAMAPASSION",
                    to: 'https://www.dramapassion.com/',
                    icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
                }
            ]
        },
        {
            id: 2,
            backgroundImage: bgKpop,
            description: 'Tous les gossip des people KPOP sont sur STAR 24.',
            duration: '',
            minimumAge: '+8',
            title: 'Actu K-pop sur STAR24',
            buttons: [
                {
                    label: "STAR 24",
                    to: 'https://star24.tv/',
                    icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
                }
            ]
        }
        // {
        //     id: 5,
        //     backgroundImage: carouselImage1,
        //     description: 'GONG et GONG MAX, les seules chaines de l’Asian Culture disponibles dans ta box ou ton abonnement TV.',
        //     duration: '',
        //     minimumAge: '+8',
        //     title: 'LES CHAINES GONG',
        //     buttons: [
        //         {
        //             label: "LVE GONG",
        //             to: '/live',
        //             icon: <FontAwesomeIcon className={'mr-2'} icon={faArrowUpRightFromSquare}/>
        //         }
        //     ]
        // },
    ];

    const renderCarouselItems = carouselItems.map((item, index) =>
        <HomePageCarouselItem key={item.title} id={index}
                              backgroundImage={carouselItems[index].backgroundImage}
                              title={carouselItems[index].title}
                              description={carouselItems[index].description}
                              duration={carouselItems[index].duration}
                              minimumAge={carouselItems[index].minimumAge}
                              buttons={item.buttons}
        />
    )


    return (
        <div className="main-slider" id="main-slider">
            <div className="slider big-slider slider-wrap">
                {renderCarouselItems}
            </div>
            {/* Slide Wrap End */}
        </div>

    )

};


